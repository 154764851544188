import swiper from './js/plugins/swiper/swiper-bundle.min.js';
import glightbox from './js/plugins/glightbox/glightbox.min.js';
global.Swiper = swiper;

import './js/src/functions';
import './js/src/modules';
import './js/src/header';
import './js/src/modals';
import './js/src/forms';
import './js/src/cookies';

document.addEventListener('DOMContentLoaded', () => {
  global.modules.init();
  glightbox();
});

// css
import './js/plugins/swiper/swiper-bundle.min.css';
import './js/plugins/glightbox/glightbox.min.css';
import './css/common.css';