global?.modules?.set({
  name: 'modals',
  is_booted: false,
  async init() {
    if (this.is_booted) {
      return;
    }
    this.is_booted = true;

    initModals();
  }
});

function initModals() {
  // Обробка кліків по кнопках відкриття модальних вікон
  const modalTriggerButtons = document.querySelectorAll('[data-modal-trigger]');
  if (modalTriggerButtons.length) {
    modalTriggerButtons.forEach((btn) => {
      btn.addEventListener('click', () => {
        const modalId = btn.dataset.modalTrigger;
        const modal = document.getElementById(modalId);
        if (modal) openModal(modal);
      });
    });
  }

  // Обробка кліків по кнопках закриття модальних вікон
  const modalCloseButtons = document.querySelectorAll('[data-close-modal]');
  if (modalCloseButtons.length) {
    modalCloseButtons.forEach((btn) => {
      btn.addEventListener('click', () => {
        const modal = btn.closest('.modal');
        if (modal) closeModal(modal);
      })
    })
  }

  document.addEventListener('keydown', event => {
    if (event.key !== 'Escape') return;

    const activeElement = document.activeElement;
    const tagName = activeElement ? activeElement.tagName.toUpperCase() : '';
    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(tagName)) return;

    const openModal = document.querySelector('.modal.is-open');
    if (openModal && typeof closeModal === 'function') closeModal(openModal);

  });

  document.addEventListener('click', event => {
    const modal = event.target.closest('.modal');
    if (!modal) return;

    if (event.target === modal && typeof closeModal === 'function') closeModal(modal);
  });
}