const bodyLock = () => {
  let body = document.body;
  scrollPosition = window.pageYOffset;
  body.classList.add('lock');
  body.style.top = `-${scrollPosition}px`;
}

global.bodyLock = bodyLock;

const bodyUnlock = () => {
  let body = document.body;
  body.classList.remove('lock');
  body.style.removeProperty('top');
  window.scrollTo(0, scrollPosition);
}

global.bodyUnlock = bodyUnlock;

const closeModal = (modal) => {
  modal.classList.add('closing');
  const scrollBarWidth = parseInt(getComputedStyle(document.body).getPropertyValue('padding-right'), 10) || 0;
  const modalOffset = parseInt(getComputedStyle(modal).getPropertyValue('padding-right'), 10) || 0;

  bodyUnlock();
  modal.style.paddingRight = `${modalOffset - scrollBarWidth}px`;

  setTimeout(() => {
    modal.className = 'modal';
    modal.removeAttribute('style');
  }, 500);
};

global.closeModal = closeModal;

const openModal = (modal) => {
  if (!modal) return;

  modal.classList.add('is-open');
  modal.removeAttribute('style');
  bodyLock();
}

global.openModal = openModal;

const sendRequest = async (method, url, body) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  };

  const res = await fetch(url, options);
  if (!res.ok) {
    const errorData = await res.json();
    throw errorData;
  }
  return res.json();
};

global.sendRequest = sendRequest;

const showNotify = ({ status, message }) => {
  const wrapper = document.querySelector('.notify-wrapper');
  if (!wrapper) return;

  const notifyBody = document.createElement('div');
  notifyBody.classList.add('notify-body');
  const notify = document.createElement('div');
  notify.classList.add('notify', status);
  notify.innerHTML = message;

  notifyBody.append(notify);
  wrapper.append(notifyBody);

  setTimeout(() => {
    notifyBody.remove();
  }, 2000);
};

global.showNotify = showNotify;
