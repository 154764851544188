global?.modules?.set({
  name: 'cookies',
  is_booted: false,
  async init() {
    if (this.is_booted) return;
    this.is_booted = true;

    initCookiesPopup()
  }
});

function initCookiesPopup() {
  const cookiePopup = document.querySelector('.cookie');
  if (!cookiePopup) return;

  const cookiePopupForm = cookiePopup.querySelector('form');
  if (!cookiePopupForm) return;

  processForm({
    form: cookiePopupForm,
    onSuccessSend: () => {
      cookiePopup.classList.remove('is-open');
    }
  });

  setTimeout(() => {
    cookiePopup.classList.add('is-open');
  }, 2000);
}
