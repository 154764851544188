global?.modules?.set({
  name: 'header',
  is_booted: false,
  async init() {
    if (this.is_booted) {
      return;
    }
    this.is_booted = true;

    initBurgerMenu();
    initDropdownMobileClose();
    initDropdownLogic();

    function initBurgerMenu() {
      const menuOpen = () => {
        bodyLock();
        document.documentElement.classList.add('menu-open');
      }

      const menuClose = () => {
        bodyUnlock();
        document.documentElement.classList.remove('menu-open');
        const activeMenu = document.querySelector('[data-dropdown-menu].active');
        if (activeMenu) activeMenu.classList.remove('active');
      }

      const burger = document.querySelector('.header__burger');
      if (burger) {
        document.addEventListener('click', (e) => {
          if (e.target.closest('.header__burger')) {
            const isMenuOpen = document.documentElement.classList.contains('menu-open');
            isMenuOpen ? menuClose() : menuOpen();
          }
        });
      }
    }

    function initDropdownMobileClose() {
      const closeBtns = document.querySelectorAll('[data-dropdown-menu] .header-dropdown__menu-title');
      if (!closeBtns.length) return;

      closeBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
          const menu = btn.closest('[data-dropdown-menu]');
          if (menu) {
            menu.classList.remove('active');
            menu.scrollTop = 0;
          }
        });
      });
    }


    function initDropdownLogic() {
      const btns = document.querySelectorAll('[data-dropdown-trigger]');
      const headerEl = document.querySelector('header.header');
      const dropdownMenus = document.querySelectorAll('[data-dropdown-menu]');
      const noDropdownLinks = document.querySelectorAll('[data-dropdown-menu-no]');
      if (!btns.length || !headerEl || !dropdownMenus.length || !noDropdownLinks.length) return;

      const eventHide = new CustomEvent('hideMenu');

      // Функції для відкриття/закриття дропдауну
      const closeAllDropdownMenus = () => {
        document.querySelectorAll('[data-dropdown-menu]').forEach((menu) => {
          menu.hidden = true;
          menu.classList.remove('active');
        });
      };

      const closeDropdownMenu = (btn) => {
        const menu = document.querySelector(`[data-dropdown-menu="${btn.dataset.dropdownTrigger}"]`);
        if (menu) {
          const dropdown = menu.closest('.header-dropdown');
          if (dropdown) dropdown.removeAttribute('style');
          menu.classList.remove('active');
          setTimeout(() => menu.dispatchEvent(eventHide), 600);
        }
      };

      const openDropdownMenu = (btn) => {
        const OFFSET_SPACE = 100;
        const menu = document.querySelector(`[data-dropdown-menu="${btn.dataset.dropdownTrigger}"]`);
        if (menu) {
          const dropdown = menu.closest('.header-dropdown');
          menu.hidden = false;
          const height = menu.offsetHeight;
          const headerHeight = headerEl.offsetHeight;
          const maxHeight = window.innerHeight - headerHeight - OFFSET_SPACE;
          if (dropdown) {
            dropdown.style.height = height + 'px';
            dropdown.style.maxHeight = maxHeight + 'px';
          }
          menu.classList.add('active');
        }
      };

      const hideDropdownMenu = () => {
        const activeBtn = document.querySelector('[data-dropdown-trigger].active');
        if (!activeBtn) return;
        closeAllDropdownMenus();
        activeBtn.classList.remove('active');
        closeDropdownMenu(activeBtn);
        headerEl.classList.remove('is-open-menu');
        bodyUnlock();
      };

      // Обробники подій
      const handleMouseLeaveHeader = () => hideDropdownMenu();
      const handleMouseEnterNoDropdownLinks = () => hideDropdownMenu();

      const handleHideMenuEvent = ({ target }) => {
        if (!target.classList.contains('active')) {
          target.hidden = true;
        }
      };

      const handleMouseEnterDropdownBtn = ({ target }) => {
        if (target.classList.contains('active')) return;
        btns.forEach((btn) => btn.classList.remove('active'));
        target.classList.add('active');
        if (!document.body.classList.contains('lock')) bodyLock();
        closeAllDropdownMenus();
        openDropdownMenu(target);
      };

      const handleClickDropdownBtn = (ev) => {
        ev.preventDefault();
        const key = ev.target.dataset.dropdownTrigger;
        const menu = document.querySelector(`[data-dropdown-menu="${key}"]`);
        if (menu) menu.classList.add('active');
      };

      // Ініціалізація логіки в залежності від розміру вікна
      const media = window.matchMedia('(min-width:79.9988em)');
      media.matches ? initPCLogic() : initMbLogic();

      media.addEventListener('change', () =>
        media.matches ? (clearMbLogic(), initPCLogic()) : (clearPCLogic(), initMbLogic())
      );

      function initPCLogic() {
        headerEl.addEventListener('mouseleave', handleMouseLeaveHeader);
        noDropdownLinks.forEach((link) =>
          link.addEventListener('mouseenter', handleMouseEnterNoDropdownLinks)
        );
        dropdownMenus.forEach((menu) =>
          menu.addEventListener('hideMenu', handleHideMenuEvent)
        );
        btns.forEach((btn) =>
          btn.addEventListener('mouseenter', handleMouseEnterDropdownBtn)
        );
      };

      function clearPCLogic() {
        headerEl.removeEventListener('mouseleave', handleMouseLeaveHeader);
        noDropdownLinks.forEach((link) =>
          link.removeEventListener('mouseenter', handleMouseEnterNoDropdownLinks)
        );
        dropdownMenus.forEach((menu) =>
          menu.removeEventListener('hideMenu', handleHideMenuEvent)
        );
        btns.forEach((btn) =>
          btn.removeEventListener('mouseenter', handleMouseEnterDropdownBtn)
        );
      };

      function initMbLogic() {
        btns.forEach((btn) =>
          btn.addEventListener('click', handleClickDropdownBtn)
        );
      };

      function clearMbLogic() {
        btns.forEach((btn) =>
          btn.removeEventListener('click', handleClickDropdownBtn)
        );
      };
    }


  },
});