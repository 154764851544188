global?.modules?.set({
  name: 'forms',
  is_booted: false,
  async init() {
    if (this.is_booted) {
      return;
    }
    this.is_booted = true;

    initForms();
  },
});

function initForms() {
  const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

  const modalContactForm = document.getElementById('contact-modal-form');
  if (modalContactForm) {
    processForm({
      form: modalContactForm,
      type: 'contact_us',
      ...queryParams,
      onSuccessSend: () => {
        const modal = document.getElementById('contact-us-modal');
        modalContactForm.reset();
        if (modal) closeModal(modal);
      },
    });
  }

  const newsletterSubscriptionForm = document.getElementById('newsletter-subscription');
  if (newsletterSubscriptionForm) {
    processForm({
      form: newsletterSubscriptionForm,
      type: 'cases_component',
      onSuccessSend: () => {
        newsletterSubscriptionForm.reset();
      },
    });
  }
}

function processForm({ form, onSuccessSend, ...data }) {
  form.addEventListener('submit', async (ev) => {
    ev.preventDefault();
    const formData = new FormData(form);
    const values = Object.fromEntries(formData);

    if (values.phone) {
      values.phone = `+380${values.phone}`;
    }

    await handleSubmit(
      { ...values, ...data },
      { onSuccess: onSuccessSend, form },
    );
  });
}

global.processForm = processForm;

const handleSubmit = async (values, { onSuccess, form }) => {
  const action = form.getAttribute('action') || '#';
  const method = form.getAttribute('method') || 'POST';

  const btn = form.querySelector('button[type="submit"]');

  btn.disabled = true;

  try {
    const response = await sendRequest(method, action, values);
    if (response.success) {
      if (onSuccess) onSuccess();
    }
    if (response.message) showNotify({ status: response.success ? 'success' : 'error', message: response.message || '' });
  } catch (error) {
    showNotify({ status: 'error', message: 'Error' });
  } finally {
    btn.disabled = false;
  }
};

global.handleSubmit = handleSubmit;