global.modules = {
  init: async function (context) {
    this.modules = [...new Map(this.modules.map(module => [module.name, module])).values()];
    await Promise.all(this.modules.map((el) => el.init(context)));
  },
  modules: [],
  set: function (module) {
    this.modules = [...this.modules, module];
  }
}
